<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="50%"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Contato
          <span
            class="body-2"
          >
            <i>{{ contact ? `Editando #${contact.id}` : 'Novo' }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="typeOfContactAssociationId"
              >
                <v-select
                  v-model="fields.typeOfContactAssociationId"
                  :items="typesOfContactsAssociations"
                  item-value="id"
                  item-text="description"
                  label="Tipo"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="name"
              >
                <v-text-field
                  v-model="fields.name"
                  hide-details="auto"
                  label="Nome"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="email"
              >
                <v-text-field
                  v-model="fields.email"
                  hide-details="auto"
                  label="E-mail"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="telephone"
              >
                <v-text-field
                  v-model="fields.telephone"
                  hide-details="auto"
                  label="Telefone"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="isPresident" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="politicalParty"
              >
                <v-text-field
                  v-model="fields.politicalParty"
                  hide-details="auto"
                  label="Partido político"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col v-if="isPresident" md="6">
              <validation-provider
                v-slot="{ errors }"
                vid="cityId"
              >
                <v-autocomplete
                  v-model="fields.cityId"
                  :items="cities"
                  auto-select-first
                  clearable
                  autocomplete="no"
                  item-value="id"
                  item-text="name"
                  label="Município"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="6">
              <v-switch
                v-model="fields.active"
                class="mt-0 pt-4"
                label="Ativo"
                hide-details
              />
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon
            left
            v-text="'mdi-content-save'"
          />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import citiesApi from '@/api/cities'
  import associationsApi from '@/api/associations'
  import typesOfContactsAssociationsApi from '@/api/types-of-contacts-associations'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      associationId: {
        type: Number,
      },
      contact: {
        type: Object,
        default: () => { },
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        typesOfContactsAssociations: [],
        cities: [],
        fields: {
          typeOfContactAssociationId: null,
          active: true,
        }
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      isPresident () {
        return this.fields.typeOfContactAssociationId === 1
      },
    },


    mounted () {
      this.load()
    },

    methods: {

      async load () {
        try {
          this.loading = true
          
          if (this.contact) {
            this.fields = this.contact || {}
          }

          const [
            responseTypesOfContactsAssociations,
            responseCities
          ] = await Promise.all([
            typesOfContactsAssociationsApi.list(),
            citiesApi.list(),
          ])

          this.typesOfContactsAssociations = responseTypesOfContactsAssociations.data.typesOfContactsAssociations
          this.cities = responseCities.data.cities
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async save () {
        this.loadingSave = true
        this.$refs.observer.reset()

        try {
          const contact = { ...this.fields }

          contact.associationId = this.associationId

          if (this.contact) {
            await associationsApi.updateContact(this.associationId, this.contact.id, contact)
          } else {
            await associationsApi.insertContact(this.associationId, contact)
          }

          this.$snackbar.show({ color: 'success', message: 'Salvo com sucesso' })
          this.show = false
          this.$emit('save')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loadingSave = false
        }
      },

    },

  }
</script>
