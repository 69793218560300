var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"50%"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"grey lighten-2"}},[_c('v-toolbar-title',[_vm._v(" Contato "),_c('span',{staticClass:"body-2"},[_c('i',[_vm._v(_vm._s(_vm.contact ? ("Editando #" + (_vm.contact.id)) : 'Novo'))])])]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary","absolute":"","bottom":"","indeterminate":""}}):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"pt-4 text-center"},[_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"typeOfContactAssociationId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.typesOfContactsAssociations,"item-value":"id","item-text":"description","label":"Tipo","hide-details":"auto","error-messages":errors},model:{value:(_vm.fields.typeOfContactAssociationId),callback:function ($$v) {_vm.$set(_vm.fields, "typeOfContactAssociationId", $$v)},expression:"fields.typeOfContactAssociationId"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Nome","error-messages":errors},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"E-mail","error-messages":errors},model:{value:(_vm.fields.email),callback:function ($$v) {_vm.$set(_vm.fields, "email", $$v)},expression:"fields.email"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Telefone","error-messages":errors},model:{value:(_vm.fields.telephone),callback:function ($$v) {_vm.$set(_vm.fields, "telephone", $$v)},expression:"fields.telephone"}})]}}])})],1),(_vm.isPresident)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"politicalParty"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Partido político","error-messages":errors},model:{value:(_vm.fields.politicalParty),callback:function ($$v) {_vm.$set(_vm.fields, "politicalParty", $$v)},expression:"fields.politicalParty"}})]}}],null,false,3787401565)})],1):_vm._e(),(_vm.isPresident)?_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"cityId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.cities,"auto-select-first":"","clearable":"","autocomplete":"no","item-value":"id","item-text":"name","label":"Município","hide-details":"auto","error-messages":errors},model:{value:(_vm.fields.cityId),callback:function ($$v) {_vm.$set(_vm.fields, "cityId", $$v)},expression:"fields.cityId"}})]}}],null,false,2029884971)})],1):_vm._e(),_c('v-col',{attrs:{"md":"6"}},[_c('v-switch',{staticClass:"mt-0 pt-4",attrs:{"label":"Ativo","hide-details":""},model:{value:(_vm.fields.active),callback:function ($$v) {_vm.$set(_vm.fields, "active", $$v)},expression:"fields.active"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","outlined":"","loading":_vm.loadingSave},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-content-save')}}),_vm._v(" Salvar ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }